<template>
  <div>
    <div v-if="getAddress.length > 0">
      <h5 class="mb-4">اختار موقعك او ادخل موقع جديد</h5>

      <div
        class="form-check row"
        v-for="address in getAddress"
        style="padding-left: 0 !important"
      >
        <input
          class="form-check-input"
          type="radio"
          name="exampleRadios"
          id="exampleRadios1"
          :value="address"
          v-model="selectedAddress"
        />
        <label class="form-check-label" for="exampleRadios1">
          المدينة: {{ address.city }} الحي:{{ address.district }} 
        </label>
      </div>
      <div class="row mt-4 d-flex justify-content-center">
        <button class="btn bwj-btn" @click="addNewAddress">
          ادخل عنوان جديد
        </button>
      </div>
    </div>
    <div v-if="getAddress.length == 0 || AddressState">
      <h5 class="my-4">ادخل بيانات موقعك</h5>
      <div class="row d-flex justify-content-between mb-2">
        <div class="col-6">
          <input
            type="text"
            class="form-control"
            placeholder=" المدينة"
            v-model="city"
            :class="{
              'is-invalid': submitted && $v.city.$error,
            }"
          />
          <div v-if="submitted && $v.city.$error" class="invalid-feedback">
            <span class="error" v-if="!$v.city.required">هذا الحقل مطلوب</span>
          </div>
        </div>

        <div class="col-6">
          <input
            type="text"
            class="form-control"
            placeholder=" الحي"
            v-model="district"
            :class="{
              'is-invalid': submitted && $v.district.$error,
            }"
          />
          <div v-if="submitted && $v.district.$error" class="invalid-feedback">
            <span class="error" v-if="!$v.district.required"
              >هذا الحقل مطلوب</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="row d-flex justify-content-between mb-2"> -->
        <!-- <div class="col-6"> -->
          <!-- <input -->
            <!-- type="tel" -->
            <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
            <!-- class="form-control" -->
            <!-- placeholder=" الرمز البريدي" -->
            <!-- v-model.trim="$v.postalCode.$model" -->
            <!-- :class="{ -->
              <!-- 'is-invalid': submitted && $v.postalCode.$error, -->
            <!-- }" -->
            <!-- maxlength="5" -->
            <!-- @keydown="handleOnKeyDown" -->
          <!-- /> -->
          <!-- <div -->
            <!-- v-if="submitted && $v.postalCode.$error" -->
            <!-- class="invalid-feedback" -->
          <!-- > -->
            <!-- <span class="error" v-if="!$v.postalCode.required" -->
              <!-- >هذا الحقل مطلوب</span -->
            <!-- > -->
            <!-- <span -->
              <!-- class="error" -->
              <!-- v-if="!$v.postalCode.minLength || !$v.postalCode.maxLength" -->
              <!-- >يجب ان يكون الرقم من خمس خانات</span -->
            <!-- > -->
            <!-- <span class="error" v-if="!$v.postalCode.numeric" -->
              <!-- >هذا الحقل مطلوب</span -->
            <!-- > -->
          <!-- </div> -->
        <!-- </div> -->
        <!-- <div class="col-6"> -->
          <!-- <input -->
            <!-- type="text" -->
            <!-- class="form-control" -->
            <!-- placeholder=" اسم الشارع" -->
            <!-- v-model="streetName" -->
            <!-- :class="{ -->
              <!-- 'is-invalid': submitted && $v.streetName.$error, -->
            <!-- }" -->
          <!-- /> -->
          <!-- <div -->
            <!-- v-if="submitted && $v.streetName.$error" -->
            <!-- class="invalid-feedback" -->
          <!-- > -->
            <!-- <span class="error" v-if="!$v.streetName.required" -->
              <!-- >هذا الحقل مطلوب</span -->
            <!-- > -->
          <!-- </div> -->
        <!-- </div> -->
      <!-- </div> -->
      <!-- <div class="row d-flex justify-content-between mb-2"> -->
        <!-- <div class="col-6"> -->
          <!-- <input -->
            <!-- type="tel" -->
            <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
            <!-- class="form-control" -->
            <!-- placeholder=" رقم المبنى" -->
            <!-- v-model.trim="$v.buildingNo.$model" -->
            <!-- :class="{ -->
              <!-- 'is-invalid': submitted && $v.buildingNo.$error, -->
            <!-- }" -->
            <!-- maxlength="4" -->
            <!-- @keydown="handleOnKeyDown" -->
          <!-- /> -->
          <!-- <div -->
            <!-- v-if="submitted && $v.buildingNo.$error" -->
            <!-- class="invalid-feedback" -->
          <!-- > -->
            <!-- <span class="error" v-if="!$v.buildingNo.required" -->
              <!-- >هذا الحقل مطلوب</span -->
            <!-- > -->
            <!-- <span -->
              <!-- class="error" -->
              <!-- v-if="!$v.buildingNo.minLength || !$v.buildingNo.maxLength" -->
              <!-- >يجب ان يكون الرقم من أربع خانات</span -->
            <!-- > -->
          <!-- </div> -->

          <!-- <span class="error" v-if="!$v.buildingNo.numeric" -->
            <!-- >هذا الحقل مطلوب</span -->
          <!-- > -->
        <!-- </div> -->

        <!-- <div class="col-6"> -->
          <!-- <input -->
            <!-- type="tel" -->
            <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
            <!-- class="form-control" -->
            <!-- placeholder=" الرقم الاضافي" -->
            <!-- v-model.trim="$v.addNo.$model" -->
            <!-- :class="{ 'is-invalid': submitted && $v.addNo.$error }" -->
            <!-- maxlength="4" -->
            <!-- @keydown="handleOnKeyDown" -->
          <!-- /> -->
          <!-- <div v-if="submitted && $v.addNo.$error" class="invalid-feedback"> -->
            <!-- <span class="error" v-if="!$v.buildingNo.required" -->
              <!-- >هذا الحقل مطلوب</span -->
            <!-- > -->
            <!-- <span -->
              <!-- class="error" -->
              <!-- v-if="!$v.addNo.minLength || !$v.addNo.maxLength" -->
              <!-- >يجب ان يكون الرقم من أربع خانات</span -->
            <!-- > -->
            <!-- <span class="error" v-if="!$v.addNo.numeric">هذا الحقل مطلوب</span> -->
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_ADDRESS } from "../graphql/mutations";
import { GET_ADDRESS } from "../graphql/queries.js";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      submitted: false,
      postalCode: null,

      buildingNo: null,
      city: null,
      district: null,
      streetName: null,
      addNo: null,

      selectedAddress: null,
      getAddress: [],
      AddressState: false,
    };
  },
  validations: {
    buildingNo: {
      //required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    city: {
      required,
    },
    district: {
      required,
    },
    streetName: {
      //required,
    },
    addNo: {
      //required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    postalCode: {
      //required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(5),
    },
  },
  apollo: {
    getAddress: {
      query: GET_ADDRESS,
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },

  methods: {
    async submitAddress() {
      if (this.selectedAddress) {
              console.log(this.selectedAddress);

        this.$store.state.glopal.selectedAddress = this.selectedAddress;
        console.log(this.$store.state.glopal.selectedAddress);
      } else {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          let newAddress = {
            // postalCode: Number(this.postalCode),
            // buildingNo: Number(this.buildingNo),
            city: this.city,
            district: this.district,
            // streetName: this.streetName,
            // addNo: Number(this.addNo),
           };
          console.log(newAddress);
          this.$apollo
            .mutate({
              // Query
              mutation: ADD_ADDRESS,
              // Parameters
              variables: { AddressInput: newAddress },
              update: (store, { data: { addAddress } }) => {
                console.log(store);
                console.log(addAddress);

                // Read the data from our cache for this query.
                const data = store.readQuery({ query: GET_ADDRESS });
                console.log(data);
                data.getAddress = addAddress;

                console.log(data.getAddress);
                store.writeQuery({
                  query: GET_ADDRESS,
                  data,
                });
              },
              // Update the cache with the result
              // The query will be updated with the optimistic response
              // and then with the real result of the mutation
              // Optimistic UI
              // Will be treated as a 'fake' result as soon as the request is made
              // so that the UI can react quickly and the user be happy
            })
            .then((data) => {
              // Result
              console.log(data);
              this.$store.state.glopal.selectedAddress =
                data.data.addAddress[0];
              this.$store.commit("glopal/isLoading", false);
            })
            .catch((error) => {
              // Error
              this.$store.commit("glopal/isLoading", false);

              console.error(error);
              // We restore the initial user input
            });
        }
        // const {
        // data
        // } = await this.$store.dispatch('glopal/addAdsress',this.newAddress);
      }
      console.log(this.$store.state.glopal.selectedAddress);
      return;
    },
    addNewAddress() {
      this.AddressState = true;
      this.selectedAddress = null;
    },
    handleOnKeyDown(event) {
      // Only allow characters 0-9, DEL, Backspace and Pasti
      const keyEvent = event || window.event;
      const charCode = keyEvent.which ? keyEvent.which : key;
      if (
        this.isCodeNumeric(charCode) ||
        charCode === 8 ||
        charCode === 86 ||
        charCode === 46 ||
        charCode === 13
      ) {
        this.$emit("on-keydown", event);
      } else {
        keyEvent.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      );
    },
  },
  computed: {
    showInput() {
      if (getAddress.length == 0) {
      }
    },
  },
};
</script>

<style></style>
