<template>
  <div class="text-center">
    <h5 class="mb-4 ">أدخل بياناتك</h5>
    <div class="row d mb-2 d-flex justify-content-between">
      <div class="col-6">
        <input
          type="text"
          class="form-control "
          placeholder="الإسم الأول"
          v-model="userInfo.Fname"
          :class="{
            'is-invalid': submitted && $v.userInfo.Fname.$error,
          }"
        />

        <div
          v-if="submitted && $v.userInfo.Fname.$error"
          class="invalid-feedback text-center"
        >
          <span class="error" v-if="!$v.userInfo.Fname.required"
            >هذا الحقل مطلوب</span
          >
        </div>
      </div>

      <div class="col-6">
        <input
          type="text"
          class="form-control "
          placeholder="إسم العائلة"
          v-model="userInfo.Lname"
          :class="{
            'is-invalid': submitted && $v.userInfo.Lname.$error,
          }"
        />
        <div
          v-if="submitted && $v.userInfo.Lname.$error"
          class="invalid-feedback text-center"
        >
          <span class="error" v-if="!$v.userInfo.Lname.required"
            >هذا الحقل مطلوب</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <input
        type="email"
        id="email"
        name="email"
        class="form-control"
        placeholder="الإيميل"
        v-model="userInfo.Email"
        :class="{
          'is-invalid': submitted && $v.userInfo.Email.$error,
        }"
      />
      <div
        v-if="submitted && $v.userInfo.Email.$error"
        class="invalid-feedback text-center"
      >
        <span class="error" v-if="!$v.userInfo.Email.required"
          >هذا الحقل مطلوب</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ADD_USER_INFO } from "../graphql/mutations";

export default {
  data() {
    return {
      submitted: false,
      userInfo: {
        Fname: "",
        Lname: "",
        Email: "",
      },
    };
  },
  validations: {
    userInfo: {
      Fname: { required },
      Lname: { required },
      Email: { required },
    },
  },
  methods: {
    saveUserInfo() {
      return new Promise((resolve, reject) => {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          reject(this.$v.$error);
          console.log(this.$v);
        } else {
          this.$store.commit("glopal/isLoading", true);

          this.$apollo
            .mutate({
              // Query
              mutation: ADD_USER_INFO,
              // Parameters
              variables: this.userInfo,
            })
            .then((data) => {
              // Result

              this.$store.commit("glopal/isLoading", false);
              console.log(data);
              resolve(data);
            })
            .catch((error) => {
              // Error
              this.$store.commit("glopal/isLoading", false);
              console.error(error);
              reject(error);
              // We restore the initial user input
            });
        }
      });
    },
  },
};
</script>
