<template>
  <div>
    <div v-if="getAddress.length > 0 && isUserHaveInfo">
      <div v-if="offerStep == 1">
        <h5 class="mb-4">معلومات التسعيرة</h5>
        <div class="row mt-1 justify-content-between">
          <div
            class="col-sm-12 input-group mb-3 col-lg-5"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ selectedRequest.manufacturer }}
            </span>
            <span class="input-group-text bw-span">الشركة المصنعة</span>
          </div>
          <div
            class="col-sm-12 input-group mb-3 col-lg-5"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ selectedRequest.model }}
            </span>
            <span class="input-group-text bw-span">السيارة</span>
          </div>
        </div>
        <div class="row mt-1 justify-content-between">
          <div
            class="col-sm-12 input-group mb-3 col-lg-5"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ selectedRequest.year }}
            </span>
            <span class="input-group-text bw-span">سنة الصنع</span>
          </div>
          <div
            class="col-sm-12 input-group mb-3 col-lg-5"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ selectedRequest.location }}
            </span>
            <span class="input-group-text bw-span">المدينة</span>
          </div>
        </div>
      </div>
      <div v-if="offerStep == 2">
        <h5 class="mb-4">ادخل بيانات العرض</h5>
        <div class="row d-flex justify-content-between mb-2">
          <div class="col-5">
            <input
              type="number"
              class="form-control "
              placeholder=" قيمة العرض"
              v-model.number="offer.offerAmount"
              maxlength="4"
              :class="{
                'is-invalid': submitted && $v.offer.offerAmount.$error,
              }"
            />
            <div
              v-if="submitted && $v.offer.offerAmount.$error"
              class="invalid-feedback"
            >
              <span class="error" v-if="!$v.offer.offerAmount.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control "
              placeholder=" قيمة الشحن"
              v-model.number="offer.shippingAmount"
              :class="{
                'is-invalid': submitted && $v.offer.shippingAmount.$error,
              }"
              maxlength="4"
            />
            <div
              v-if="submitted && $v.offer.shippingAmount.$error"
              class="invalid-feedback"
            >
              <span class="error" v-if="!$v.offer.shippingAmount.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>

        <div class="row d-flex align-items-center my-4">
          <div class="col-lg-6 col-sm-12">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="isThereGarntee"
            />
            <label class="form-check-label mr-4"> يوجد ضمان للقطعة</label>
          </div>
          <div class="d-flex align-items-center col-lg-6 col-sm-12 row">
            <div
              class="col-12 row"
              style="padding-left: 0;
    margin-right: 0;"
            >
              <input
                type="number"
                class="col-9 form-control"
                placeholder="عدد ايام الضمان"
                v-model.number="offer.insurancePeriod"
                maxlength="4"
                :class="{
                  'is-invalid': submitted && $v.offer.insurancePeriod.$error,
                }"
                :disabled="!isThereGarntee"
              />

              <h5 class="m-auto col-3">يوم</h5>
            </div>
            <div
              v-if="submitted && $v.offer.insurancePeriod.$error"
              class="invalid-feedback col-12"
              style="    display: block;"
            >
              <span class="error" v-if="!$v.offer.insurancePeriod.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>

        <div class="row d-flex align-items-center my-4">
          <div class="col-lg-6 col-sm-12">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="offer.acceptsCod"
            />
            <label class="form-check-label mr-4"> يوجد دفع عند الاستلام </label>
          </div>

          <div class="d-flex align-items-center col-lg-6 col-sm-12 row">
            <div
              class="col-12 row"
              style="padding-left: 0;
    margin-right: 0;"
            >
              <input
                type="number"
                class="col-9 form-control"
                placeholder="الدفع عند الاستلام"
                v-model.number="offer.codCharge"
                maxlength="4"
                :class="{
                  'is-invalid': submitted && $v.offer.codCharge.$error,
                }"
                :disabled="!offer.acceptsCod"
              />
              <h5 class="m-auto col-3">ريال</h5>
              <span
                class="mx-auto mt-2 text-danger"
                style="font-size:small"
                v-if="offer.codCharge > 30"
                >هذا الحقل فقط لقيمة خدمة الدفع عند الاستلام</span
              >
            </div>

            <div
              v-if="submitted && $v.offer.codCharge.$error"
              class="invalid-feedback col-12"
              style="    display: block;"
            >
              <span class="error" v-if="!$v.offer.codCharge.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center my-4 mx-0">
          <div class="d-flex align-items-right col-6 col-sm-12 row m-0">
            <h5>
              أقصى وقت للتوصيل
            </h5>
          </div>
          <div class="d-flex align-items-left col-6 col-sm-12 row m-0">
            <div
              class="col-12 row"
              style="padding-left: 0;
               margin-right: 0;"
            >
              <input
                type="number"
                class="col-6 form-control"
                placeholder="أقصى وقت للتوصيل"
                v-model.number="offer.deliveryPeriod"
                maxlength="4"
                :class="{
                  'is-invalid': submitted && $v.offer.deliveryPeriod.$error,
                }"
              />
              <h5 class="m-auto col-6">يوم عمل</h5>
            </div>
            <div
              v-if="submitted && $v.offer.deliveryPeriod.$error"
              class="invalid-feedback col-12"
              style="    display: block;"
            >
              <span class="error" v-if="!$v.offer.deliveryPeriod.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center my-4 mx-0">
          <div class="d-flex align-items-right col-6 col-sm-12 row m-0">
            <h5>
              عدد ايام الاسترجاع
            </h5>
          </div>
          <div class="d-flex align-items-left col-6 col-sm-12 row m-0">
            <div
              class="col-12 row"
              style="padding-left: 0;
           margin-right: 0;"
            >
              <input
                type="number"
                class="col-6 form-control"
                placeholder=" عدد ايام الاسترجاع"
                v-model.number="offer.returnPeriod"
                maxlength="4"
                :class="{
                  'is-invalid': submitted && $v.offer.returnPeriod.$error,
                }"
              />
              <h5 class="m-auto col-6">يوم</h5>
            </div>
            <div
              v-if="submitted && $v.offer.returnPeriod.$error"
              class="invalid-feedback col-12"
              style="    display: block;"
            >
              <span class="error" v-if="!$v.offer.returnPeriod.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center my-4 mx-0">
          <div class="d-flex align-items-right col-6 col-sm-12 row m-0">
            <h5>
              عدد ايام الاستبدال
            </h5>
          </div>
          <div class="d-flex align-items-left col-6 col-sm-12 row m-0">
            <div
              class="col-12 row"
              style="padding-left: 0;
           margin-right: 0;"
            >
              <input
                type="number"
                class="col-6 form-control"
                placeholder="  عدد ايام الاستبدال"
                v-model.number="offer.replacePeriod"
                maxlength="4"
                :class="{
                  'is-invalid': submitted && $v.offer.replacePeriod.$error,
                }"
              />
              <h5 class="m-auto col-6">يوم</h5>
            </div>
            <div
              v-if="submitted && $v.offer.replacePeriod.$error"
              class="invalid-feedback col-12"
              style="    display: block;"
            >
              <span class="error" v-if="!$v.offer.replacePeriod.required"
                >هذا الحقل مطلوب</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 mb-2">
          <input
            type="checkbox"
            class="form-check-input mr-2"
            v-model="offer.acceptsCollect"
          />
          <label class="form-check-label" style="margin-right: 2rem !important">
            يوجد استلام من المتجر
          </label>
        </div>
        <div class="row">
          <input
            type="text"
            class="form-group bwj-w form-control"
            placeholder="وصف القطعة..."
            style="height: 8rem !important"
            v-model="offer.comment"
          />
        </div>
        <div class="row">
          <label
            for="file-upload"
            class="form-control border border-success"
            style="font-size: 1.5em"
          >
            أرفع صور للقطعة
            <i class="camera icon"></i>
          </label>
          <input
            @change="setImage"
            id="file-upload"
            type="file"
            accept="image/*"
            multiple
          />
          <h5 id="imagesLength">عدد الصور{{ offer.images.length }}</h5>
          <i
            v-if="offer.images.length > 0"
            class="trash alternate outline icon text-success"
            @click="offer.images = []"
          ></i>
          <div
            v-if="submitted && $v.offer.images.$error"
            class="invalid-feedback col-12"
            style="    display: block;"
          >
            <span
              class="error mb-2 font-weight-bold"
              v-if="!$v.offer.images.required"
              >يجب رفع صورة على الاقل</span
            >
          </div>
        </div>
      </div>
      <button
        class="btn btn-danger btn-sm mx-2"
        @click="offerStep = offerStep - 1"
        v-if="offerStep > 1"
      >
        الرجوع
      </button>
      <button class="btn bwj-btn btn-sm mx-2" @click="submmit">
        التالي
      </button>
    </div>
    <div class="mt-4 container text-center" v-if="!isUserHaveInfo">
      <user-info ref="userInfo"> </user-info>
      <button class="btn bwj-btn btn-sm my-2" @click="submmit">
        التالي
      </button>
    </div>
    <div class=" text-center" v-if="getAddress.length == 0 && isUserHaveInfo">
      <app-address></app-address>
      <button class="btn bwj-btn btn-sm mx-2" @click="submmit">
        حفظ الموقع
      </button>
    </div>
  </div>
</template>

<script>
import address from "../Address.vue";
import Compressor from "compressorjs";
import {
  GET_ALL_REQUSTS,
  GET_ADDRESS,
  GET_USER_INFO,
} from "../../graphql/queries";
import { POST_OFFER } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import userInfo from "../userInfio.vue";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
    reader.onerror = (error) => reject(error);
  });
}

export default {
  data() {
    return {
      submitted: false,
      isThereGarntee: false,
      getAddress: [],
      isUserHaveInfo: false,
      offerStep: 1,
      offer: {
        offerAmount: null,
        shippingAmount: null,
        addressId: null,
        acceptsCod: false,
        acceptsCollect: false,
        images: [],
        comment: null,
        insurancePeriod: 10,
        codCharge: 10,
        returnPeriod: 3,
        replacePeriod: 10,
        deliveryPeriod: 7,
      },
    };
  },
  apollo: {
    getAddress: {
      query: GET_ADDRESS,
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
    getUserInfo: {
      query: GET_USER_INFO,
      result(result) {
        if (result.data.getUserInfo.firstName) {
          this.isUserHaveInfo = true;
        }
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    "app-address": address,
    userInfo: userInfo,
  },
  props: ["selectedRequest"],
  validations() {
    const offer = {
      offer: {
        offerAmount: {
          required,
        },
        shippingAmount: {
          required,
        },
        insurancePeriod: {},
        codCharge: {},
        images: {
          required,
        },
        returnPeriod: { required },
        replacePeriod: { required },
        deliveryPeriod: { required },
      },
    };
    if (this.isThereGarntee) {
      console.log(offer.offer);
      offer.offer.insurancePeriod = { required };
    }

    if (this.offer.acceptsCod) {
      console.log(offer.offer);

      offer.offer.codCharge = { required };
    }
    return offer;
  },
  methods: {
    async setImage(event) {
      let compressedImages = [];
      for (const key of event.target.files) {
        const image = await new Compressor(key, {
          quality: 0.6,
          strict: true,
          height: 560,
          convertSize: 200000,
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            getBase64(result).then((res) => {
              let ImageFile = { fileName: result.name, fileBody: res };
              compressedImages.push(ImageFile);
              console.log(compressedImages);
            });
          },
          error(err) {
            console.log(err.message);
          },
        });
      }
      this.offer.images = compressedImages;
      console.log(this.offer.images);
    },
    async submmit(e, payload) {
      if (!this.isUserHaveInfo) {
        this.$refs.userInfo
          .saveUserInfo()
          .then((data) => {
            console.log(data);
            this.AddressStepN = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.getAddress.length == 0) {
        this.$store.commit("glopal/isLoading", true);

        await this.$children[0].submitAddress();
        this.showModal = false;
        this.$store.commit("glopal/isLoading", false);
      }
      if (this.offerStep == 1) {
        this.offerStep = 2;
      } else if ((this.offerStep = 2)) {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log("error");
        } else {
          this.$store.state.glopal.showModal = false;
          this.$store.commit("glopal/isLoading", true);
          if (!this.isThereGarntee) {
            this.offer.insurancePeriod = 0;
          }
          if (!this.offer.acceptsCod) {
            this.offer.codCharge = 0;
          }
          this.offer.addressId = this.getAddress[0]._id;
          // let offer = {
          // RID: this.selectedRequest._id,
          // address: this.$store.getters["glopal/selectedAddress"]._id,
          // offerIn: this.offer,
          // };
          console.log(this.offer);
          //this.$store.dispatch("glopal/postOffer", offer).then((o) => {

          this.$apollo
            .mutate({
              // Query
              mutation: POST_OFFER,
              // Parameters
              variables: {
                RequestId: this.selectedRequest._id,
                OfferData: this.offer,
              },
              update: (store, { data: { postOffer } }) => {
                console.log(store);
                console.log(postOffer);
                console.log(this.selectedRequest);

                const data = store.readQuery({ query: GET_ALL_REQUSTS });
                console.log(data);
                data.getRequests[
                  this.selectedRequest.originalIndex
                ].offers.splice(0, 0, postOffer);
                console.log(data.getRequests);
                store.writeQuery({
                  query: GET_ALL_REQUSTS,
                  data,
                });
              },
            })
            .then((data) => {
              // Result
              this.$store.commit("glopal/isLoading", false);
              console.log(data);
              this.offerStep = 1;
              this.selectedRequest._id = null;
              this.isThereGarntee = false;
              this.offer = {
                offerAmount: null,
                shippingAmount: null,
                addressId: null,
                acceptsCod: false,
                acceptsCollect: false,
                images: [],
                comment: null,
                insurancePeriod: null,
                codCharge: 0,
              };
              $("html, body").animate({ scrollTop: 0 }, "slow");

              this.$store.dispatch("alert/success", "تم اضافة عرض جديد");
              console.log(data);
            })
            .catch((error) => {
              // Error
              this.$store.commit("glopal/isLoading", false);
              console.error(error);
            });
        }
      }
    },
  },
};
</script>

<style>
img.preview {
  width: auto;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"] {
  display: none;
}

.custom-file-input::-webkit-file-upload-text {
  visibility: hidden;
}

.custom-file-input::before {
  visibility: visible;
  content: "Upload Your Image";
  -webkit-user-select: none;
  cursor: pointer;
}
h6{
color: rgba(9, 88, 137, 1);
}
.custom-control-label::before, 
.custom-control-label::after {
top: .8rem;
width: 1.25rem;
height: 1.25rem;
}

</style>
